import { createContext, useContext, useReducer } from "react";

import type { ReactNode } from "react";

interface SimpleNotificationData {
  type: "simple";
  title: ReactNode;

  closeDelayMs?: number;
  description?: ReactNode;
  icon?: ReactNode;
}

export type NotificationProps = SimpleNotificationData;

interface NotificationData {
  id: number;
  active: boolean;
  props: NotificationProps;

  cancel?: () => void;
}

type Action =
  | { type: "append"; data: NotificationData }
  | { type: "close"; id: number }
  | { type: "kill"; id: number };
type State = { notifications: NotificationData[]; nextNotificationId: number };

interface NotificationContextType {
  appendNotification: (data: NotificationProps) => void;
  killNotification: (id: number) => void;
  closeNotification: (id: number) => void;
  notifications: NotificationData[];
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const notificationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "append":
      return {
        nextNotificationId: state.nextNotificationId + 1,
        notifications: state.notifications.concat(action.data),
      } as State;
    case "close":
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.id ? { ...notification, active: false } : notification,
        ),
      } as State;
    case "kill":
      return {
        ...state,
        notifications: state.notifications.filter((notification) => {
          if (notification.id === action.id) {
            notification.cancel?.();
          }
          return notification.id !== action.id;
        }),
      } as State;
    default:
      throw new Error("should not get here");
  }
};

interface Props {
  children?: ReactNode;
}

export const NotificationProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(notificationReducer, {
    notifications: [],
    nextNotificationId: 0,
  });

  const value: NotificationContextType = {
    notifications: state.notifications,
    appendNotification(notificationProps) {
      const closeDelayMs = notificationProps.closeDelayMs ?? 4000;
      const n: NotificationData = {
        id: state.nextNotificationId,
        active: true,
        props: notificationProps,
      };
      // allow for non-autoclosing notifications by having the auto close delay be 0
      if (closeDelayMs > 0) {
        const timeoutId = setTimeout(() => {
          dispatch({ type: "close", id: n.id });
        }, closeDelayMs);
        n.cancel = () => clearTimeout(timeoutId);
      }
      dispatch({ type: "append", data: n });
    },
    closeNotification(id) {
      dispatch({ type: "close", id });
    },
    killNotification(id) {
      dispatch({ type: "kill", id });
    },
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("NotificationContext must be used in a NotificationProvider");
  }
  return context;
};
