import Head from "next/head";
import { SessionProvider as NextAuthProvider } from "next-auth/react";

import { NotificationProvider } from "@components/notification-context";
import NotificationArea from "@components/NotificationArea";

import type { AppProps /*, AppContext */ } from "next/app";

import "../styles/index.css";

type MyComponentType<P> = AppProps<P>["Component"] & {
  layout?: React.ComponentType<React.PropsWithChildren<P>>;
};

interface MyAppProps<P> extends AppProps<P> {
  Component: MyComponentType<P>;
}

type P = Record<string, unknown>;
const DummyLayout: NonNullable<MyComponentType<P>["layout"]> = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }: MyAppProps<P>): JSX.Element {
  const Layout = Component.layout ?? DummyLayout;

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <NextAuthProvider session={pageProps.session} baseUrl={process.env.NEXT_PUBLIC_ROOT_URL}>
        <NotificationProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
          <NotificationArea />
        </NotificationProvider>
      </NextAuthProvider>
    </>
  );
}

export default MyApp;
