// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || "development";

Sentry.init({
  dsn: SENTRY_DSN || "https://90b19cf53d8e4cbd8bdef72d1d914821@o913763.ingest.sentry.io/5851978",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: environment === "development" ? 0.1 : 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment,
  tunnel: "/api/sentry",
  //debug: process.env.CI || ["development", "test"].includes(process.env.NODE_ENV),
});
