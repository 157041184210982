import { forwardRef } from "react";
import { XIcon } from "@heroicons/react/solid";

import type { ReactNode, Ref } from "react";

type Props = {
  title: ReactNode;
  onClose: () => void;
  description?: ReactNode;
  icon: ReactNode;
};

const SimpleNotification = (
  { title, description, icon, onClose }: Props,
  ref: Ref<HTMLDivElement>,
): JSX.Element => {
  return (
    <div
      className="pointer-events-auto z-50 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      ref={ref}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}
              data-testid="close-button"
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SimpleNotification);
