import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import SimpleNotification from "@components/notifications/SimpleNotification";

import { useNotificationContext } from "./notification-context";

const NotificationArea = (): JSX.Element => {
  const { notifications, killNotification, closeNotification } = useNotificationContext();

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end ">
        {notifications.map((notification) => (
          <Transition
            key={notification.id}
            show={notification.active}
            as={Fragment}
            afterLeave={() => killNotification(notification.id)}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {notification.props.type === "simple" ? (
              <SimpleNotification
                key={notification.id}
                onClose={() => closeNotification(notification.id)}
                title={notification.props.title}
                description={notification.props.description}
                icon={notification.props.icon}
              />
            ) : (
              /* istanbul ignore next */
              "Should not get here"
            )}
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default NotificationArea;
